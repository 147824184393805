<template>
  <div>
    <div
      class="flex bg-gray-100 dark:bg-trueGray-800 flex-row-reverse h-screen overflow-hidden"
    >
      <div class="flex flex-col w-0 flex-1">
        <TheHeader />
        <!-- <MainHeader @open="showMenu" /> -->

        <main class="flex-1 relative focus:outline-none h-full -mt-14 pt-14">
          <!-- <RouterView /> -->
          <slot />
          <!-- <LectureNotification class="fixed bottom-0 z-10" /> -->
        </main>
      </div>

      <!-- <div
        v-if="notifications.length"
        class="fixed bottom-0 right-0 z-50 flex flex-col"
      >
        <NotificationsContainer />
      </div> -->

      <!-- <MainMenu /> -->
      <!-- <MenuWrap /> -->
    </div>
  </div>
</template>
